<template>
  <section class="video-section">
    <div class="wrapper">
      <h2 class="video-section__title">{{ title }}</h2>
      <div class="video-section__slider" :class="{ single: media?.length <= 1 }">
        <ClientOnly>
          <Swiper
            class="slider-image-text-block"
            :modules="[Pagination, Navigation]"
            :slides-per-view="1"
            :space-between="16"
            :auto-height="false"
            create-elements
            :navigation="media?.length > 1"
            :pagination="media?.length > 1"
            :breakpoints="breakpoints"
          >
            <SwiperSlide v-for="(slide, index) in media" :key="`slider_text_block_${index}`">
              <div class="video-card" @click="openMediaModal(index)">
                <div class="video-card__img">
                  <div class="video-card__img-inner">
                    <video
                      v-if="slide.previewVideo"
                      :controls="false"
                      playsinline
                      loop
                      muted
                      autoplay
                      :poster="`${url}${slide.image.source}`"
                    >
                      <source :src="`${url}${slide.previewVideo}`" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <UIPictureTag
                      v-else
                      :image-original="`${url}${slide.image.source}`"
                      :image-seo="slide.imageSeo"
                      :image-webp="`${url}${slide.image.webp}`"
                      :is-visible="true"
                    />
                  </div>
                </div>
                <div class="video-card__content">
                  <div v-if="slide.name" class="video-card__name">{{ slide.name }}</div>
                  <div v-if="slide.text" class="video-card__desc">
                    {{ slide.text }}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </ClientOnly>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import type { ImageItem, ImageSEO } from '~/types/pages';
import { computed, onUnmounted, useMediaModal, useRuntimeConfig } from '#imports';

interface Props {
  title: string;
  media: {
    image: ImageItem;
    imageSeo: ImageSEO;
    link: string;
    previewVideo: string;
    text: string;
    name: string;
  }[];
}

const breakpoints = {
  1025: {
    slidesPerView: 3,
    spaceBetween: 32,
    centeredSlides: false,
  },

  475: {
    slidesPerView: 2,
    spaceBetween: 16,
    centeredSlides: false,
  },
};

const props = defineProps<Props>();

const url = useRuntimeConfig().public.siteUrl;

const dataSource = computed(() =>
  props.media?.map(item => {
    return {
      html: `<iframe src="${item.link}?rel=0"
                    frameborder="0"
                    allow="
                     accelerometer;
                     autoplay;
                     clipboard-write;
                     encrypted-media;
                     gyroscope;
                     picture-in-picture;
                     web-share"
                    allowfullscreen>
                   </iframe>`,
    };
  }),
);

const { destroyMediaModal, openMediaModal } = useMediaModal(dataSource.value);

onUnmounted(() => destroyMediaModal());
</script>
